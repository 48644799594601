import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import Button from '../components/Button/Button'
import Carousel from '../components/Carousel/Carousel'
import Container from '../components/Container/Container'
import Hero from '../components/Hero/Hero'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO/SEO'
import Section from '../components/Section/Section'
import VideoModal from '../components/VideoModal/VideoModal'

import './index.css'
import Checklist from '../components/Checklist/Checklist'
import SubtleCallout from '../components/SubtleCallout/SubtleCallout'

const HomePage = ({
  data: {
    getInvolvedImagesData: { edges: getInvolvedImagesEdges },
    valuesIconsData: { edges: valuesIconsEdges },
    membershipCalloutImageData: { childImageSharp: membershipCalloutImageSharp },
  },
}) => (
    <Layout>
      <SEO title="A Community for Black People in Technology" />
      <Hero title="A Community for Black People in Technology">
        <p style={{ maxWidth: '720px' }}>We are an educational and community building platform meant to unite, inform and encourage Black people in the tech industry.</p>
      </Hero>
      <Carousel />
      <Section>
        <Container>
          <h2>Get Involved</h2>
          <div className="GetInvolved--cards">
            <Link to="/about">
              <h3>About</h3>
              <Img
                {...getInvolvedImagesEdges
                  .find(({ node: { relativePath } }) => relativePath.includes('--about.jpg'))
                  .node.childImageSharp
                }
              />
              <Img
                className="image--hover"
                {...getInvolvedImagesEdges
                  .find(({ node: { relativePath } }) => relativePath.includes('--about--hover.jpg'))
                  .node.childImageSharp
                }
              />
            </Link>
            <Link to="/resources">
              <h3>Resources</h3>
              <Img
                {...getInvolvedImagesEdges
                  .find(({ node: { relativePath } }) => relativePath.includes('--resources.jpg'))
                  .node.childImageSharp
                }
              />
              <Img
                className="image--hover"
                {...getInvolvedImagesEdges
                  .find(({ node: { relativePath } }) => relativePath.includes('--resources--hover.jpg'))
                  .node.childImageSharp
                }
              />
            </Link>
            <Link to="/membership">
              <h3>Membership</h3>
              <Img
                {...getInvolvedImagesEdges
                  .find(({ node: { relativePath } }) => relativePath.includes('--membership.jpg'))
                  .node.childImageSharp
                }
              />
              <Img
                className="image--hover"
                {...getInvolvedImagesEdges
                  .find(({ node: { relativePath } }) => relativePath.includes('--membership--hover.jpg'))
                  .node.childImageSharp
                }
              />
            </Link>
          </div>
        </Container>
      </Section>
      <Section className="MissionStatement light">
        <Container>
          <h2>Mission Statement &amp; Culture</h2>
          <p>Our mission is to accelerate the growth of the Black tech ecosystem by helping members obtain opportunities in the industry and sustain long-term, rewarding careers. We are here to cultivate a close-knit community and provide a safe space for people who either work in the tech industry or aspire to do so. </p>
          <div className="MissionStatement--values">
            <div>
              <Img
                {...valuesIconsEdges
                  .find(({ node: { relativePath } }) => relativePath.includes('Development'))
                  .node.childImageSharp
                }
              />
              <h4>Development</h4>
            </div>
            <div>
              <Img
                {...valuesIconsEdges
                  .find(({ node: { relativePath } }) => relativePath.includes('Education'))
                  .node.childImageSharp
                }
              />
              <h4>Education</h4>
            </div>
            <div>
              <Img
                {...valuesIconsEdges
                  .find(({ node: { relativePath } }) => relativePath.includes('Diversity'))
                  .node.childImageSharp
                }
              />
              <h4>Diversity</h4>
            </div>
            <div>
              <Img
                {...valuesIconsEdges
                  .find(({ node: { relativePath } }) => relativePath.includes('Liberation'))
                  .node.childImageSharp
                }
              />
              <h4>Liberation</h4>
            </div>
          </div>
          <Button href="/about">More about our Mission &amp; Culture</Button>
        </Container>
      </Section>
      <Section className="MemberBenefits">
        <Container>
          <h2>Member Benefits</h2>
          <div className="MemberBenefits--content">
            <Checklist
              items={[
                'Active Community',
                'Member Directory',
                'Job Board',
                'Workshops',
                'Event Calendar',
                'Mentorship',
                'Webinars',
                'Community Conversations',
              ]}
            />
            <div className="MemberBenefits--video-wrapper">
              <VideoModal />
            </div>
          </div>
        </Container>
        <SubtleCallout
          ctaText="Learn more about membership"
          imageSharp={membershipCalloutImageSharp}
          title="Join a community of Black people in the tech&nbsp;industry."
          to="/membership"
        />
      </Section>
    </Layout>
  )

export const pageQuery = graphql`
  query HomePageQuery {
    getInvolvedImagesData: allFile(filter: { relativePath: { regex: "/GetInvolved\/GetInvolved/" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 398, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    valuesIconsData: allFile(filter: { relativePath: { regex: "/ValuesIcons\//" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            fixed(width: 120, quality: 80) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    }
    membershipCalloutImageData: file(relativePath: { eq: "HomePage/HomePage--MembershipCallout.png" }) {
      childImageSharp {
        fluid(maxWidth: 458, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default HomePage
