import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import './SubtleCallout.css'

const SubtleCallout = ({
  ctaText,
  description,
  to,
  imageSharp,
  title,
}) => (
    <div className="SubtleCallout">
      <div className="SubtleCallout--content">
        <h3 dangerouslySetInnerHTML={{ __html: title }} />
        {description && <p>{description}</p>}
        <Link to={to}>
          {ctaText}
          <svg
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.74951 5L1.94345 9.53674e-07L0.499512 1.50221L3.87834 4.98261L0.499512 8.49779L1.94345 10L6.74951 5Z"
              fill="#B6EDED"
            />
          </svg>
        </Link>
      </div>
      {imageSharp && (
        <div className="SubtleCallout--image">
          <Img {...imageSharp} />
        </div>
      )}
    </div>
  )

SubtleCallout.propTypes = {
  ctaText: PropTypes.string.isRequired,
  description: PropTypes.string,
  to: PropTypes.string.isRequired,
  imageSharp: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}

export default SubtleCallout
