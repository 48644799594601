import React from 'react';

import ChecklistIconSVG from './ChecklistIcon.svg'

import './Checklist.css'

const Checklist = ({ items }) => (
  <ul className="Checklist">
    {items.map(item => (
      <li key={item}>
        <img src={ChecklistIconSVG} alt="Icon: Checkmark" />
        {item}
      </li>
    ))}
  </ul>
)

export default Checklist
