import React, { useEffect, useMemo, useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import './Carousel.css'

const Carousel = () => {
  const {
    imagesData: { edges: imagesEdges }
  } = useStaticQuery(graphql`
    query CarouselQuery {
      imagesData: allFile(
        filter: { relativePath:{regex: "/Carousel\/Carousel/"}}
        sort: { fields: relativePath }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 826, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    
  `)

  const makeSlide = index => ({
    index,
    key: `slide--${Math.floor(Math.random() * 123456)}`,
  })

  const totalSlides = imagesEdges.length

  const initialSlides = useMemo(() => {
    let index = totalSlides - 3
    return Array(5)
      .fill(null)
      .map((_, i) => {
        index++
        if (index > totalSlides - 1) index = 0
        return makeSlide(index)
      })
  }, [totalSlides])

  const [slides, setSlides] = useState(initialSlides)

  const slideIntervalRef = useRef()

  useEffect(() => {
    const step = () => {
      setSlides(prevSlides => {
        const slicedSlides = prevSlides.slice(1, 5)
        let newSlideIndex = slicedSlides[slicedSlides.length - 1].index + 1
        if (newSlideIndex > totalSlides - 1) newSlideIndex = 0
        const _slides = [
          ...slicedSlides,
          makeSlide(newSlideIndex)
        ]
        return _slides
      })

      slideIntervalRef.current = setTimeout(step, 7500)
    }

    slideIntervalRef.current = setTimeout(step, 7500)

    return () => {
      if (slideIntervalRef.current) clearTimeout(slideIntervalRef.current)
    }
  }, [imagesEdges, totalSlides])

  return (
    <div className="Carousel">
      <div className="Carousel--slides">
        {slides.map(({ index, key }) => {
          const { childImageSharp } = imagesEdges[index].node
          return (
            <Img
              key={key}
              {...childImageSharp}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Carousel
