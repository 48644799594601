import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

import PlayIconSVG from './VideoModal--play.svg'

import './VideoModal.css'

const YOUTUBE_ID = '5Gvjulo3Ujk'

const VideoModal = () => {
  const {
    videoPreviewImageData: { childImageSharp: videoPreviewImageSharp },
  } = useStaticQuery(graphql`
    query VideoModalQuery {
      videoPreviewImageData: file(relativePath: { eq: "VideoModal/VideoModal--preview.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1174, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [showModal]);

  return (
    <div className="VideoModal">
      <button className="play" onClick={() => setShowModal(true)}>
        <Img
          className="video-preview"
          fluid={videoPreviewImageSharp.fluid}
          alt="Click to watch Member Benefits Video"
        />
        <img
          className="play-icon"
          src={PlayIconSVG}
          alt="Click to watch Member Benefits Video"
        />
      </button>
      {showModal && (
        <div className="modal">
          <button className="close" onClick={() => setShowModal(false)}><span>&times;</span></button>
          <div class="iframe-wrapper">
            <div>
              <iframe
                allow="accelerometer;clipboard-write;encrypted-media;gyroscope;picture-in-picture"
                allowFullScreen
                frameBorder="0"
                src={`https://www.youtube.com/embed/${YOUTUBE_ID}?autoplay=1`}
                title="Member Benefits Video"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default VideoModal
